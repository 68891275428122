import React from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import "./Footer.css";
import icon1 from "../../assets/Home1/01Asset73.png";
import icon2 from "../../assets/Home1/01Asset74.png";
import icon3 from "../../assets/Home1/01Asset75.png";
import discord from "../../assets/discord.png";

import WebConnect from "../connect"
import { Link } from 'react-router-dom'

const Footer = () => {
  const [address, setAddress] = React.useState("Connect")

  React.useEffect(() => {

    setAddress(WebConnect.getAddress())
    const interval = setInterval(() => {
        setAddress(WebConnect.getAddress())
    }, 1000);

    return () => {};
  }, [address]);

  return (
    <div className="bg-footer footer">
    <div style={{ alignItems: 'center', alignText: 'center', height: '100%'}} >
      <Row style={{ alignItems: 'center', alignText: 'center', height: '100%', marginLeft: '0px'}} >
        <img src={icon1} alt="logo" className="logo v-align mr-4" />
      </Row>
      </div>
    </div>
  );
};
export default Footer;
