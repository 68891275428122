import WebConnect from "../../components/connect"
import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "./Mint.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

// import img1 from "../../assets/Mint/01Asset17.png";
import img2 from "../../assets/Mint/01Asset14.png";
import img1 from "../../assets/Mint/bg.jpeg";
import data from "./data";
import Bar from "../../components/bar/Bar";
import banner from "../../assets/banners/bannertransp.png";

const Mint = () => {
  const [amountToBuy, setAmountToBuy] = useState(0);
  const [isActive, setIsActive] = useState(false)
  const [mintPrice, setMintPrice] = useState(0.06)
  const [totalMinted, setTotalMinted] = useState(7000)

  useEffect(() => {

    const interval = setInterval(() => {
      if(WebConnect.state.web3){
        setIsActive(WebConnect.state.isSaleActive)
        setMintPrice(WebConnect.state.mintPrice)
        setTotalMinted(WebConnect.state.totalMinted_g1)
      }
    }, 1000);

  }, []);

  const minMax = (value) => {
    if(parseInt(value) < 0 || isNaN(parseInt(value)))
        return 0;
    else if(parseInt(value) > 25)
        return 25;
    else return value;
  }

  const purchaseNFTs = async () => {
    if(WebConnect.state.web3)
      await WebConnect.mintGenOne(amountToBuy)
  }

  return (
    <>
      <Header />
      <div className="container-fluid ">
        <Col className="outer-row">
        <div className="desktop-mint">
        <img
          src={img2}
          alt="Minting Instructions"
          className="mint-img minting-instructions-img"
        />
        </div>
          <Col className="desktop-mint">
              <div className="minting-instructions-div ">
                <div>
                <p>
                <b>{totalMinted}/10000 unique Gen 1 Hashguises </b> have been created.
                <br />
                <br />
                [Next final mint date: <b>October 2nd @ 3PM UTC</b>]
                </p>
                <p>
                  <br />
                  <b>A total of 30 Gen 1's and 1 Gen 0</b> will
                  <br />
                  be airdropped to random minters of this batch every 1000 minted.
                </p>
                <p>
                  You are able to mint up to 25 Gen 1's in a single
                  <br />
                  transaction. Each Gen 1 costs <b>{mintPrice} ETH</b>.
                  <br />
                </p>
                </div>
                <Col>
                  <div className="desktop-mint">
                    <Row>
                    <label>
                    <b>Mint amount</b> - <a target="_blank" rel="noopener noreferrer" href='https://etherscan.io/address/0xed595cfd58d32bb9d200aab5b9afd9dbdebdf9f4'>Contract</a>
                    <br /><br />
                    <input type="number" value={amountToBuy} onChange={(e) => setAmountToBuy(minMax(e.target.value))} />
                    <br /><br />
                    <Button disabled={!isActive} className="password-btn" onClick={() => {
                      purchaseNFTs()
                    }}>Submit</Button>
                    </label>
                    </Row>
                  </div>
                </Col>
            </div>
          </Col>
          <Col lg="4" className="mobile-mint">
            <div className="d-flex align-items-center">
              <div className="minting-instructions-div">
                <img
                  src={img2}
                  alt="Minting Instructions"
                  className="mint-img-mobile"
                />
                <div className="min-text">
                <p>
                <b>{totalMinted}/10000 unique Gen 1 Hashguises </b> have been created.
                <br />
                <br />
                [Next final mint date: <b>October 2nd @ 3PM UTC</b>]
                </p>
                <p>
                  <br />
                  <b>A total of 30 Gen 1's and 1 Gen 0</b> will
                  <br />
                  be airdropped to random minters of this batch every 1000 minted.
                </p>
                <p>
                  You are able to mint up to 25 Gen 1's in a single
                  <br />
                  transaction. Each Gen 1 costs <b>{mintPrice} ETH</b>.
                  <br />
                </p>
                </div>


                  <Col>
                    <div className="mobile-mint">
                      <Row>
                        <label>
                          <b>Mint amount</b>
                          <br /><br />
                          <input type="number" value={amountToBuy} onChange={(e) => setAmountToBuy(minMax(e.target.value))} />
                          <br /><br />
                          <Button disabled={!isActive} className="password-btn" onClick={() => {
                            purchaseNFTs()
                          }}>Submit</Button>
                          </label>
                      </Row>
                    </div>
                  </Col>
              </div>
            </div>
          </Col>

        </Col>
      </div>
      <img
        src={banner}
        alt="Asset9"
        style={{width: '100%', paddingTop: '0'}}
      />
      <Footer />
    </>
  );
};
export default Mint;
