/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import "./Home.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import img1 from "../../assets/Home1/01Asset78.png";
import img2 from "../../assets/Home1/01Asset79.png";
import img3 from "../../assets/Home1/01Asset80.png";
import img4 from "../../assets/Home1/01Asset81.png";
import img5 from "../../assets/Home1/01Asset83.png";
import img6 from "../../assets/Home1/01Asset85.png";
import img7 from "../../assets/Home1/01Asset82.png";
import img8 from "../../assets/Home1/01Asset84.png";
import img9 from "../../assets/Home1/01Asset86.png";
import img10 from "../../assets/Home1/01Asset87.png";

import secimg1 from "../../assets/Home2/001.png";
import secimg2 from "../../assets/Home2/002.png";
import secimg3 from "../../assets/Home2/003.png";
import secimg from "../../assets/Home2/005.png";

import secimg4 from "../../assets/Home3/01Asset99.png";
import secimg5 from "../../assets/Home3/01Asset100.png";
import secimg6 from "../../assets/Home3/01Asset101.png";
import secimg7 from "../../assets/Home3/01Asset102.png";
import secimg8 from "../../assets/Home3/01Asset103.png";

import secimg9 from "../../assets/Home4/01Asset108.png";
import secimg10 from "../../assets/Home4/01Asset109.png";
import secimg11 from "../../assets/Home4/01Asset110.png";
import secimg12 from "../../assets/Home4/01Asset111.png";
import secimg13 from "../../assets/Home4/01Asset112.png";
import secimg14 from "../../assets/Home4/01Asset113.png";

import secimg15 from "../../assets/Home5/01Asset117.png";
import secimg16 from "../../assets/Home5/01Asset118.png";
import secimg17 from "../../assets/Home6/01Asset119.png";
import secimg18 from "../../assets/Home6/01Asset122.png";

import banner from "../../assets/banners/BannerMainSite-01.png";

const Home = () => {
  return (
    <>
      <Header />
      <div className="hero-section container-fluid">
        <Row>
          <Col lg="5" md="5" sm="5" xs="5">
            <Row>
              <Col lg="5" md="5" sm="5" xs="5">
                <img
                  src={img1}
                  alt="Asset1"
                  className="home-img2 display"
                  style={{ padding: "23% 18% 0% 0%" }}
                />
                <img
                  src={img10}
                  alt="Asset10"
                  className="home-img2 display"
                  style={{ padding: "40% 0% 0% 0%" }}
                />
              </Col>
              <Col lg="7" md="7" sm="7" xs="7">
                <img
                  src={img2}
                  alt="Asset2"
                  className="home-img2"
                  style={{ padding: "15% 8% 3%" }}
                />
                <img
                  src={img3}
                  alt="Asset3"
                  className="home-img2"
                  style={{ padding: "9% 0% 0% 17%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col lg="7" md="7" sm="7" xs="7">
            <Row>
              <Col lg="5" md="5" sm="5" xs="5">
                <img src={img4} alt="Asset4" className="home-img2" />
              </Col>
              <Col lg="3" md="3" sm="3" xs="3">
                <img src={img5} alt="Asset5" className="home-img2" />
              </Col>
              <Col lg="4" md="4" sm="4" xs="4">
                <img src={img6} alt="Asset6" className="home-img2" />
              </Col>
            </Row>
            <Row>
              <Col lg="3" md="3" sm="3" xs="3">
                <img
                  src={img7}
                  alt="Asset7"
                  className="home-img2"
                  style={{ padding: "19% 0% 0% 17%" }}
                />
              </Col>
              <Col lg="6" md="6" sm="6" xs="6">
                <img
                  src={img8}
                  alt="Asset8"
                  className="home-img2"
                  style={{ padding: "9% 7% 0% 7%" }}
                />
              </Col>
              <Col lg="3" md="3" sm="3" xs="3">
                <img
                  src={img9}
                  alt="Asset9"
                  className="home-img2"
                  style={{ padding: "34% 0% 0% 18%" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ background: "#1b1b1b" }}>
        <img
          src={banner}
          alt="Asset9"
          className="home-img2"
        />
        </div>
      </div>
      <div className="section2">
        <Container>
          <Row>
            <Col lg="4" md="4" sm="4" xs="4">
              <img src={secimg1} alt="Frame 1" className="home-img2" />
            </Col>
            <Col lg="4" md="4" sm="4" xs="4">
              <img src={secimg2} alt="Frame 2" className="home-img2" />
            </Col>
            <Col lg="4" md="4" sm="4" xs="4">
              <img src={secimg3} alt="Frame 3" className="home-img2" />
            </Col>
          </Row>
          <Row>
            <Col className="text-center ">
              <span className="section2-text">
                A unique digital art collection living in the blockchain.
                <br />
                100 Gen0 and 10,000 Gen1 HashGuises to be distributed employing proof of ownership.
                <br />
                Hold...or sell to the best bidder.
              </span>
            </Col>
          </Row>
        </Container>
      </div>
          <div className="section3 container-fluid desktop">
            <div className="inner-div ">
              <Row style={{paddingRight: '20px'}}>
                <Col
                  lg="3"
                  md="3"
                  sm="12"
                  xs="12"
                  className="img_center d-flex align-items-center"
                >
                  <img
                    src={secimg4}
                    alt="Asset 4"
                    className="home-img section3-img"
                  />
                </Col>
                <Col lg="9" md="9" sm="12" xs="12">
                  <Row>
                    <Col
                      lg="3"
                      md="3"
                      sm="3"
                      xs="3"
                      style={{ borderRight: "4px solid black" }}
                    >
                      <img
                        src={secimg5}
                        alt="Mute Masks"
                        className="text-img mutemask-img"
                      />
                    </Col>
                    <Col lg="9" md="9" sm="9" xs="9">
                      <div>
                        <span>
                          HashGuises is a new{" "}
                          <b>digital celebration of attires;</b>
                          <br />a rare collection of 100 Gen0 and 10,000 Gen1 unique characters
                          stored in the Ethereum
                          <br /> chain in the form of singular Non-Fungible
                          Tokens.
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="3"
                      md="3"
                      sm="3"
                      xs="3"
                      style={{ borderRight: "4px solid black" }}
                    >
                      <img
                        src={secimg6}
                        alt="About the Art"
                        className="text-img aboutart-img"
                      />
                    </Col>
                    <Col lg="9" md="9" sm="9" xs="9">
                      <div className="m-t-1">
                        <span>
                          All HashGuises are based on identifiable line paths
                          that create a<br />
                          bidimensional static and{" "}
                          <b>eye-appealing costume. </b>
                          Each mask leads to
                          <br />
                          an eye-to-eye interaction that brings out an unusual
                          but exciting
                          <br />
                          communication between the masterpiece and the
                          observer, disguising,
                          <br />
                          and exposing at the same time, strong emotions that
                          escape from the
                          <br />
                          closed boundaries of the frames.
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="3"
                      md="3"
                      sm="3"
                      xs="3"
                      style={{ borderRight: "4px solid black" }}
                    >
                      <img
                        src={secimg7}
                        alt="About the artist"
                        className="text-img aboutartist-img"
                      />
                    </Col>
                    <Col lg="9" md="9" sm="9" xs="9">
                      <div className="m-t-1">
                        <span>
                        The Hashguise Art was created by <b>Luciana Guerra, Phd. in Fine Arts.</b>
                        <br />
                        You can see more about her BIO and work <a href="https://lucianaguerra.com/">here</a>.
                        She is currently an external advisor to the Hashguise team.
                        <br />
                        <br />
                        The artist explores numerous
                        lines drawn layer by layer, that, combined with
                        <b> vibrant live pigments,</b> cover the essence and
                        complexity of a modern
                        yet simple individual: "the way she is
                        captivated by the uniqueness of the
                        life of every human being".
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      lg="3"
                      md="3"
                      sm="3"
                      xs="3"
                      style={{ borderRight: "4px solid black" }}
                    >
                      <img
                        src={secimg8}
                        alt="About the rarity"
                        className="text-img aboutrarity-img"
                      />
                    </Col>
                    <Col lg="9" md="9" sm="9" xs="9">
                      <div className="m-t-1">
                        <span>
                          HashGuises were created using individual purposes,
                          rarity in this case  <br /> is not categorized and grouped by specific items.
                          Instead, each mask presents <br /> a level
                          of uniqueness that cannot be compared  to one another.
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

          {/* For Mobile */}
          <div className="section3 container-fluid mobile">
            <div className="inner-div ">
              <Row>
                <Col
                  lg="3"
                  md="3"
                  sm="12"
                  xs="12"
                  className="img_center d-flex align-items-center"
                >
                  <img
                    src={secimg4}
                    alt="Asset 4"
                    className="home-img section3-img"
                  />
                </Col>
                <Col lg="9" md="9" sm="12" xs="12">
                  <Row>
                    <Col xs="12" className="d-flex justify-content-center">
                      <img
                        src={secimg5}
                        alt="Mute Masks"
                        className="text-img mutemask-img"
                      />
                    </Col>
                    <Col xs="12">
                      <div className="section3-text">
                        <span>
                          HashGuises is a new{" "}
                          <b>digital celebration of attires;</b>
                          <br />a rare collection of 100 Gen0 and 10,000 Gen1 unique characters
                          stored in the Ethereum
                          <br /> chain in the form of singular Non-Fungible
                          Tokens.
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="d-flex justify-content-center">
                      <img
                        src={secimg6}
                        alt="About the Art"
                        className="text-img aboutart-img"
                      />
                    </Col>
                    <Col xs="12">
                      <div className="section3-text">
                        <span>
                          All HashGuises are based on identifiable line paths
                          that create a<br />
                          bidimensional static and{" "}
                          <b>eye-appealing costume. </b>
                          Each mask leads to
                          <br />
                          an eye-to-eye interaction that brings out an unusual
                          but exciting
                          <br />
                          communication between the masterpiece and the
                          observer, disguising,
                          <br />
                          and exposing at the same time, strong emotions that
                          escape from the
                          <br />
                          closed boundaries of the frames.
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="d-flex justify-content-center">
                      <img
                        src={secimg7}
                        alt="About the artist"
                        className="text-img aboutartist-img"
                      />
                    </Col>
                    <Col xs="12">
                      <div className="section3-text">
                        <span>
                        The Hashguise Art was created by <b>Luciana Guerra, Phd. in Fine Arts.</b>
                        <br />
                        You can see more about her BIO and work <a href="https://lucianaguerra.com/">here</a>.
                        She is currently an external advisor to the Hashguise team.
                        <br />
                        <br />
                        The artist explores numerous
                        lines drawn layer by layer, that, combined with
                        <b> vibrant live pigments,</b> cover the essence and
                        complexity of a modern
                        yet simple individual: "the way she is
                        captivated by the uniqueness of the
                        life of every human being".
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="d-flex justify-content-center">
                      <img
                        src={secimg8}
                        alt="About the rarity"
                        className="text-img aboutrarity-img"
                      />
                    </Col>
                    <Col xs="12">
                      <div className="section3-text">
                        <span>
                        HashGuises were created using individual purposes,
                        rarity in this case  <br /> is not categorized and grouped by specific items.
                        Instead, each mask presents a level of uniqueness that cannot be compared to one another.
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>

      <Footer />

    </>
  );
};
export default Home;
