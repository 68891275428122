import React from "react";

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import BigNumber from "bignumber.js";
//import tableData from "../data";
import CONTRACTS from "./contracts"

const HASHGUISE_CONTRACT = {
                            GEN0: '0x19324ecf39eebbaa7bf3a217d88101436e7cb229',
                            GEN1: '0xed595cfd58d32bb9d200aab5b9afd9dbdebdf9f4',
                            ALBUM: '0x2915102b53566ea524999c31489effbae24bb880'
                          }

class WebConnect extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      web3: null,
      addressConnected: 'Connect',
      totalMinted_g0: "Locked",
      totalMinted_g1: "Locked",
      balance_g0: 'Locked',
      balance_g1: 'Locked',
      balance_album: 'Locked',
      mintPrice: '0.06',
      ownedIndexesG0: [],
      ownedIndexesG1: [],
      isSaleActive: false,
      whitelisted: false,

    }

    setInterval(() => {
      this.onChangeData()
    }, 10000);
  }

  disconnect () {
    this.state = {
      web3: null,
      addressConnected: 'Connect',
      totalMinted_g0: "Locked",
      totalMinted_g1: "Locked",
      balance_g0: 'Locked',
      balance_g1: 'Locked',
      balance_album: 'Locked',
      mintPrice: '0.06',
      ownedIndexesG0: [],
      ownedIndexesG1: [],
      isSaleActive: false,
      whitelisted: false
    }
  }

  getAddress(){
    if(this.state.addressConnected == 'Connect')
      return this.state.addressConnected

    return '0x' + this.state.addressConnected[2] + this.state.addressConnected[3] + this.state.addressConnected[4] +
      '...' + this.state.addressConnected[this.state.addressConnected.length - 3] + this.state.addressConnected[this.state.addressConnected.length - 2]
      + this.state.addressConnected[this.state.addressConnected.length - 1]
  }

  async onWeb3Connect (prov){
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: { infuraId: "99a833caa93445b488506442552de208" },
      }
    };

    const web3Modal = new Web3Modal({
      network: "mainnet",
      cacheProvider: false,
      providerOptions,
    });

    const provider = await web3Modal.connectTo(prov);
    const _web3 = new Web3(provider);
    window.send = (e,t) => { return provider.send(e,t) }
    this.state.web3 = _web3
    this.onChangeData()
  };

  async getOwner(){
    if(this.state.web3){
      let hashguise_contract_g0 = new this.state.web3.eth.Contract(CONTRACTS.GEN0[HASHGUISE_CONTRACT.GEN0], HASHGUISE_CONTRACT.GEN0);
      let hashguise_contract_g1 = new this.state.web3.eth.Contract(CONTRACTS.GEN1[HASHGUISE_CONTRACT.GEN1], HASHGUISE_CONTRACT.GEN1);

      var promArray = []
      var promArray2 = []

      for(let index = 0; index < this.state.balance_g0; index++){
        promArray.push(hashguise_contract_g0.methods.tokenOfOwnerByIndex(this.state.addressConnected, index).call())
      }

      for(let index = 0; index < this.state.balance_g1; index++){
        promArray2.push(hashguise_contract_g1.methods.tokenOfOwnerByIndex(this.state.addressConnected, index).call())
      }

      try{
        var res = await Promise.all(promArray)
        var res2 = await Promise.all(promArray2)
      } catch(e){
        console.log(e);
        return
      }
      this.state.ownedIndexesG0 = []
      this.state.ownedIndexesG1 = []
      for(let index = 0; index < res.length; index++){
        this.state.ownedIndexesG0.push(res[index])
      }
      for(let index = 0; index < res2.length; index++){
        this.state.ownedIndexesG1.push(res2[index])
      }
    }
  }

  async getOwnerByID(index){
    if(this.state.web3){
      let hashguise_contract_g0 = new this.state.web3.eth.Contract(CONTRACTS.GEN0[HASHGUISE_CONTRACT.GEN0], HASHGUISE_CONTRACT.GEN0);
      let hashguise_contract_g1 = new this.state.web3.eth.Contract(CONTRACTS.GEN1[HASHGUISE_CONTRACT.GEN1], HASHGUISE_CONTRACT.GEN1);

      try{
        var res = await hashguise_contract_g0.methods.ownerOf(index).call();
        return {address: res, color: false}
      } catch(e){ }

      try{
        var res = await hashguise_contract_g0.methods.ownerOf(Number(index) + 100).call();
        return {address: res, color: true}
      } catch (e) {
        return {address: '0x000...000', color: false}
      }
    }
    return {address: '0x000...000', color: false}
  }

  async onChangeData() {
    if(this.state.web3){
      const accounts = await this.state.web3.eth.getAccounts();
      let addrConnected = accounts[0]
      this.state.addressConnected =  addrConnected

      let hashguise_contract_g0 = new this.state.web3.eth.Contract(CONTRACTS.GEN0[HASHGUISE_CONTRACT.GEN0], HASHGUISE_CONTRACT.GEN0);
      let hashguise_contract_g1 = new this.state.web3.eth.Contract(CONTRACTS.GEN1[HASHGUISE_CONTRACT.GEN1], HASHGUISE_CONTRACT.GEN1);
      let album_contract = new this.state.web3.eth.Contract(CONTRACTS.ALBUM[HASHGUISE_CONTRACT.ALBUM], HASHGUISE_CONTRACT.ALBUM);

      try{
        var res = await Promise.all([
          hashguise_contract_g0.methods.totalSupply().call(),
          hashguise_contract_g1.methods.totalSupply().call(),

          hashguise_contract_g0.methods.balanceOf(addrConnected).call(),
          hashguise_contract_g1.methods.balanceOf(addrConnected).call(),

          hashguise_contract_g1.methods.saleIsActive().call(),

          hashguise_contract_g1.methods.CURRENT_PRICE().call(),
          album_contract.methods._whitelistedAccounts(addrConnected).call(),
          album_contract.methods.balanceOf(addrConnected, 1).call(),
        ])
      } catch(e){
        console.log(e);
        return
      }

      this.state.totalMinted_g0 = res[0]
      this.state.totalMinted_g1 = res[1]
      this.state.balance_g0 = res[2]
      this.state.balance_g1 = res[3]

      this.state.isSaleActive = res[4]

      this.state.mintPrice = new BigNumber(res[5]).div(Math.pow(10,18)).toFixed()

      this.state.whitelisted = res[6]
      this.state.balance_album = res[7]

      await this.getOwner()

    }
  }

  async mintGenOne(amount){
      try{
        if(amount > 25 || amount <= 0)
          return

        var mint_price = new BigNumber(this.state.mintPrice).times(Math.pow(10,18)).times(amount).toFixed()
        let hashguise_contract_g1 = new this.state.web3.eth.Contract(CONTRACTS.GEN1[HASHGUISE_CONTRACT.GEN1], HASHGUISE_CONTRACT.GEN1);

        var step_1 = await hashguise_contract_g1.methods.mint(amount)
        this.state.inProcess = true;
        var res = await step_1.send({from: this.state.addressConnected, value: mint_price})
        this.state.inProcess = false;
        this.onChangeData()
        return res.transactionHash
      } catch (e) {
        console.log(e)
        return null
      }
  }

  async mintAlbum(){
      try{
        let album_contract = new this.state.web3.eth.Contract(CONTRACTS.ALBUM[HASHGUISE_CONTRACT.ALBUM], HASHGUISE_CONTRACT.ALBUM);
        var step_1 = await album_contract.methods.mint(this.state.addressConnected, 1)
        this.state.inProcess = true;
        var res = await step_1.send({from: this.state.addressConnected})
        this.state.inProcess = false;
        this.onChangeData()
        return res.transactionHash
      } catch (e) {
        console.log(e)
        return null
      }
  }

}

export default (new WebConnect());
