import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import "./Gallery.css";
import {colorURL, blackURL, characters} from "../../components/info"

var passwordCorrect = true

const Gallery = () => {
  const [muteMasksData, setMuteMasksData] = React.useState([]);
  const [passwordEntered, setPasswordEntered] = React.useState(passwordCorrect)
  const [password, setPassword] = React.useState('')

  React.useEffect(() => {
    var tot = []
    for(let index = 0; index < 100; index++){
      tot.push({
        id: index,
        title: characters[index],
        img: colorURL[index],
        owner: "0x3e73d09...2f68de46",
        lastPrice: "7.05 ETH",
        date: "Mar 20, 2021",
      })
    }
    if(muteMasksData.length != tot.length)
      setMuteMasksData(tot)

    return () => {};
  });


  return (
    <>
      <Header />
      <div className="container-fluid" style={{ padding: "3% 4%" }}>

        {passwordEntered == true ?
          (        <Row>
                    {muteMasksData.map((data, i) => (
                      <Col lg="3" md="4" sm="6" xs="6" className="gallery" key={i}>
                        <Link to={`/gallery-detail/${data.id}`} params={{ id: data.id }}>
                          <img src={data.img} alt={data.title} className="gallery-img" />
                        </Link>
                        <div className="cards-info">
                          <b>Title:&nbsp;{data.title}</b>
                        </div>
                      </Col>
                    ))}
                  </Row>) :
                  (
                    <>
                    <Row>
                    <label>
                    For now, a password is required to enter the HashGuise gallery. <br />
                    Please follow the instructions provided on our <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hashguise">twitter</a> account <br /><br />

                     Password: &nbsp;
                      <input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
                     </label>
                    </Row>
                    <Button className="password-btn" onClick={() => {
                      if(password == 'CbACey6g6g6H69Fr'){
                        passwordCorrect = true
                        setPasswordEntered(passwordCorrect)
                      }
                    }}>Submit</Button>
                    </>
                  )


        }

      </div>
      <Footer/>
    </>
  );
};
export default Gallery;
