import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./Header.css";
import icon1 from "../../assets/Home1/01Asset73.png";
import icon2 from "../../assets/Home1/01Asset74.png";
import icon3 from "../../assets/Home1/01Asset75.png";
import discord from "../../assets/discord.png";

import WebConnect from "../connect"
import { Link } from 'react-router-dom'

const Header = () => {
  const [address, setAddress] = React.useState("Connect")

  React.useEffect(() => {
    setAddress(WebConnect.getAddress())
    WebConnect.onWeb3Connect('injected')
    const interval = setInterval(() => {
        setAddress(WebConnect.getAddress())
    }, 1000);

    return () => {};
  }, [address]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-header header"
      variant="secondary"
    >
      <Navbar.Brand href="/">
        <img src={icon1} alt="logo" className="logo v-align mr-4" />
        <span className="v-align">HashGuise</span>

      </Navbar.Brand>
      <a href='https://twitter.com/hashguise' target="_blank" rel="noopener noreferrer">
        <img src={icon2} alt="logo" className="twitter-icon ml-3" />
      </a>
      <a href='https://medium.com/@hashguise' target="_blank" rel="noopener noreferrer">
      <img src={icon3} alt="logo" className="logo-icons ml-3" />
      </a>
      <a href='https://discord.com/invite/yWS5C37qvk' target="_blank" rel="noopener noreferrer">
        <img src={discord} alt="logo" className="twitter-icon ml-3" />
      </a>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link as={Link} to="/">Home</Nav.Link>
          <Nav.Link as={Link} to="/mint1">Mint Gen1</Nav.Link>
          <Nav.Link as={Link} to="/gallery">Gen0 Gallery</Nav.Link>
          <Nav.Link as={Link} to="/wallet">Wallet</Nav.Link>
          <Nav.Link as={Link} onClick={()=> WebConnect.onWeb3Connect('injected') }>{address}</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default Header;
