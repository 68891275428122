/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./AlbumMint.css";
import img1 from "../../assets/Home1/01Asset74.png";
import img2 from "../../assets/Home1/01Asset75.png";
import discord from "../../assets/discord.png";

import { BsBoxArrowInDownLeft } from "react-icons/bs";
// import * as IoIcons from "react-icons/io";
import { BiAlbum, BiTrendingUp } from "react-icons/bi";
import { BsBarChartFill, BsInfoCircleFill } from "react-icons/bs";
import WebConnect from "../connect"

const AlbumMint = () => {
  const [whitelisted, setWhitelisted] = useState('Locked');
  const [albumAmount, setAlbumAmount] = useState('Locked');

  const [fees, setfees] = useState();

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if(WebConnect.state.totalMinted_g0 != "Locked"){
      setWhitelisted(WebConnect.state.whitelisted > 0 ? 'True' : 'False')
      setAlbumAmount(WebConnect.state.balance_album)
    }
  }, []);

  const openPopup = () => {
    if(WebConnect.state.totalMinted_g0 != "Locked"){
      setWhitelisted(WebConnect.state.whitelisted > 0 ? 'True' : 'False')
      setAlbumAmount(WebConnect.state.balance_album)
    }
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const mintAlbum = async () => {
    if(whitelisted == 'True'){
      await WebConnect.mintAlbum()
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "10px",
        left: "30px",
      }}
    >
      <div style={{ position: "relative"}}>
        {showPopup && (
          <div className="album">
            <div className="album-head">
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <span>Eligibility</span>
                <div>
                  <BsBoxArrowInDownLeft
                    className="stats-img"
                    onClick={closePopup}
                  />
                </div>
              </div>
            </div>
            <div className="album-body p-2">
              <div>
                <div className="d-flex mt-1 align-items-center mb-3">
                  <div>
                    <BsBarChartFill className="m-2" fontSize={"24px"} />
                  </div>
                  <div style={{ width: "80%", paddingLeft: "4px" }}>
                    <div className="d-flex justify-content-between">
                      <div>Whitelisted to mint</div>
                      <div>{whitelisted}&nbsp;</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginTop: "-1.5px" }}
                    >
                      <div>Owned Albums</div>
                      <div>{albumAmount}&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{justifyContent: 'center', textAlign: 'center', alignItems: 'center'}}>
              <button className="stats-btn" onClick={mintAlbum}>
                Mint Album
              </button>
              </div>

            </div>
          </div>
        )}
        <button className="stats-btn" onClick={openPopup}>
          Album Mint
        </button>
      </div>
    </div>
  );
};
export default AlbumMint;
