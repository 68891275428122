/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, Table } from "react-bootstrap";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./GalleryDetail.css";
import {colorURL, blackURL, characters} from "../../components/info"
import WebConnect from "../../components/connect"

const GalleryDetail = () => {
  const [image, setImage] = React.useState(colorURL[window.location.pathname.split('/')[2]])
  const [title, setTitle] = React.useState(characters[window.location.pathname.split('/')[2]])

  const [owner, setOwner] = useState("0x000...000");
  React.useEffect(async () => {
    var _owner = await WebConnect.getOwnerByID(window.location.pathname.split('/')[2]);
    setOwner(_owner.address)

    if(_owner.color == true)
      setImage(colorURL[window.location.pathname.split('/')[2]])

    return () => {};
  }, [owner]);


  const [transactionHistory, setTransactionHistory] = useState([

  ]);
  return (
    <>
      <Header />
      <div
        className="container-fluid"
        style={{ padding: "0% 5% 6% 5%", backgroundColor: "#cccccc" }}
      >
        <Row className="gallery-detail-row">
          <Col lg="4" md="4" sm="6" xs="12" className="center_img">
            <img src={image} alt="HashGuise" className="detail-img" />
          </Col>
          <Col lg="8" md="8" sm="12" xs="12" className="gallery-detail">
            <h3 className="d-flex justify-content-center">{title}</h3>
            <span>
              <p>Owned by:&nbsp;</p>
              <span style={{ lineBreak: "anywhere" }}>{owner}</span>
            </span>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};
export default GalleryDetail;
