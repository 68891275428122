/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./Popup.css";
import img1 from "../../assets/Home1/01Asset74.png";
import img2 from "../../assets/Home1/01Asset75.png";
import discord from "../../assets/discord.png";

import { BsBoxArrowInDownLeft } from "react-icons/bs";
// import * as IoIcons from "react-icons/io";
import { BiAlbum, BiTrendingUp } from "react-icons/bi";
import { BsBarChartFill, BsInfoCircleFill } from "react-icons/bs";
import WebConnect from "../connect"

const Popup = () => {
  const [g0Minted, setG0Minted] = useState('Locked');
  const [g1Minted, setG1Minted] = useState('Locked');

  const [ownedGen0, setOwnedGen0] = useState('Locked');
  const [ownedGen1, setOwnedGen1] = useState('Locked');

  const [fees, setfees] = useState();

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if(WebConnect.state.totalMinted_g0 != "Locked"){
      setG0Minted(WebConnect.state.totalMinted_g0)
      setG1Minted(WebConnect.state.totalMinted_g1)
      setOwnedGen0(WebConnect.state.balance_g0)
      setOwnedGen1(WebConnect.state.balance_g1)
    }
  }, []);
  const openPopup = () => {
    if(WebConnect.state.totalMinted_g0 != "Locked"){
      setG0Minted(WebConnect.state.totalMinted_g0)
      setG1Minted(WebConnect.state.totalMinted_g1)
      setOwnedGen0(WebConnect.state.balance_g0)
      setOwnedGen1(WebConnect.state.balance_g1)
    }
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "10px",
        right: "4px",
      }}
    >
      <div style={{ position: "relative" }}>
        {showPopup && (
          <div className="popup">
            <div className="popup-head">
              <div
                className="d-flex align-items-center"
                style={{ justifyContent: "space-between" }}
              >
                <span>Statistics</span>
                <div>
                  <a href='https://twitter.com/hashguise' target="_blank" rel="noopener noreferrer">
                    <img src={img1} alt="twit" className="stats-img" />
                  </a>
                  <a href='https://medium.com/@hashguise' target="_blank" rel="noopener noreferrer">
                  <img
                    src={img2} alt="dots"
                    className="stats-img"
                    style={{ height: "16px" }}
                  />
                  </a>
                  <a href='https://discord.com/invite/yWS5C37qvk' target="_blank" rel="noopener noreferrer">
                    <img src={discord} alt="twit" className="stats-img" />
                  </a>

                  <BsBoxArrowInDownLeft
                    className="stats-img"
                    onClick={closePopup}
                  />
                </div>
              </div>
            </div>
            <div className="popup-body p-2">
              <div>
                <div className="d-flex mt-1 align-items-center mb-3">
                  <div>
                    <BiAlbum className="m-2" fontSize={"24px"} />
                  </div>
                  <div style={{ width: "80%", paddingLeft: "4px" }}>
                    <div className="d-flex justify-content-between">
                      <div>Gen 1 minting fee</div>
                      <div>{0.08}&nbsp;ETH</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-1 align-items-center mb-3">
                  <div>
                    <BsBarChartFill className="m-2" fontSize={"24px"} />
                  </div>
                  <div style={{ width: "80%", paddingLeft: "4px" }}>
                    <div className="d-flex justify-content-between">
                      <div>Gen 0 Minted</div>
                      <div>{g0Minted}&nbsp;</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginTop: "-1.5px" }}
                    >
                      <div>Gen 1 Minted</div>
                      <div>{g1Minted}&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mt-1 align-items-center">
                  <div>
                    <BiTrendingUp className="m-2" fontSize={"24px"} />
                  </div>
                  <div style={{ width: "80%", paddingLeft: "4px" }}>
                    <div className="d-flex justify-content-between">
                      <div>Owned Gen 0</div>
                      <div>{ownedGen0}</div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginTop: "-1.5px" }}
                    >
                      <div>Owned Gen 1</div>
                      <div>{ownedGen1}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <button className="stats-btn" onClick={openPopup}>
          Live Stats <BsInfoCircleFill className="info-icon" />
        </button>
      </div>
    </div>
  );
};
export default Popup;
