import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./pages/home/Home";
import Mint from "./pages/mint/Mint";
import Mint1 from "./pages/mint1/Mint";
import Gallery from "./pages/gallery/Gallery";
import Wallet from "./pages/wallet/Wallet";
import GalleryDetail from "./pages/galleryDetail/GalleryDetail";
//import StHash from "./pages/sthash/stHash";
import Popup from "./components/popup/Popup";
import AlbumMint from "./components/album/AlbumMint";

// import "./App.css";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/mint0" component={Mint} />
          <Route exact path="/mint1" component={Mint1} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/wallet" component={Wallet} />
          <Route exact path="/gallery-detail/:id" component={GalleryDetail} />
        </Switch>
      </Router>
      <Popup />
      <AlbumMint />
    </>
  );
}

export default App;
