/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "../gallery/Gallery.css";
import {colorURL, blackURL, characters} from "../../components/info"
import WebConnect from "../../components/connect"
import {RANKINGS} from './rankings_60'
import DATA from '../../lambda/meta.json';
import SHUFFLED_DATA_50 from '../../lambda/shuffle.json';

const Wallet = () => {
  const [ownedMutemasks, setOwnedMutemasks] = useState("Locked");
  const [yourWallet, setYourWallet] = useState("Connect");
  const [muteMasksData, setMuteMasksData] = useState([]);

  const updateState = () => {
    setYourWallet(WebConnect.state.addressConnected)
    if(!WebConnect.state.web3)
      return
    setOwnedMutemasks(Number(WebConnect.state.balance_g0) + Number(WebConnect.state.balance_g1))
    var tot = []
    for(let index = 0; index < WebConnect.state.ownedIndexesG0.length; index++){
      var pos = WebConnect.state.ownedIndexesG0[index]
      tot.push({
        title: characters[pos],
        img: colorURL[pos],
        acquisitionPrice: "7.05ETH",
        asquisitionDate: "Mar 20, 2021",
        txn: "0x3e73d09...2f68de462",
        maskState: 'colored',
        genType: 'Zero'
      })
    }

    for(let index = 0; index < WebConnect.state.ownedIndexesG1.length; index++){
      var pos = WebConnect.state.ownedIndexesG1[index]
      pos = pos - 2
      console.log(pos)
      if(pos == -1)
        tot.push({
          title: 'Genesis',
          img: 'https://ipfs.io/ipfs/QmUznRvFoont4o5FXo5dwxgCuW8LZZU2nRrjjnPXkt7yG7',
          acquisitionPrice: "7.05ETH",
          asquisitionDate: "Mar 20, 2021",
          txn: "0x3e73d09...2f68de462",
          maskState: 'colored',
          genType: 'One',
          accessories: 2,
          id: pos + 2,
          ranking: RANKINGS[pos]
        })
      else
        tot.push({
          title: DATA[SHUFFLED_DATA_50[pos]].character,
          img: 'https://ipfs.io/ipfs/' + DATA[SHUFFLED_DATA_50[pos]].url,
          acquisitionPrice: "7.05ETH",
          asquisitionDate: "Mar 20, 2021",
          txn: "0x3e73d09...2f68de462",
          maskState: 'colored',
          genType: 'One',
          id: pos + 2,
          ranking: RANKINGS[pos],
          accessories: Number(DATA[SHUFFLED_DATA_50[pos]].face_accessory.length + DATA[SHUFFLED_DATA_50[pos]].head_accessory.length + DATA[SHUFFLED_DATA_50[pos]].body_accessory.length)
        })
    }

    setMuteMasksData(tot)
  }

  React.useEffect(() => {
    updateState()
    const interval = setInterval(() => {
        updateState()
    }, 1000);

    return () => {};
  }, []);

  return (
    <>
      <Header />
      <div
        className="container-fluid"
        style={{ padding: "0 4%", backgroundColor: "#f9b9b9" }}
      >
        <div className="mask-wallet">
          <span>
            <p>Your Wallet:</p>&nbsp;
            <span style={{ lineBreak: "anywhere" }}>{yourWallet}</span>
          </span>
          <span>
            <p>Owned HashGuise:</p>&nbsp;<span>{ownedMutemasks}</span>
          </span>
        </div>
        <Row>
          {muteMasksData.map((muteMasks) => (
            <>
              <Col
                lg="3"
                md="4"
                sm="6"
                xs="12"
                className={
                  muteMasks.maskState === "black & white"
                    ? "wallet"
                    : "wallet m-t-12"
                }
              >
                {muteMasks.maskState === "black & white" ? (
                  <Button className="redeem-btn" onClick={() => WebConnect.mintColor(muteMasks.id)}>Redeem Colored Version</Button>
                ) : (
                  ""
                )}
                <img
                  src={muteMasks.img}
                  alt="HashGuise"
                  className="wallet-img "
                />
                <div className="cards-info">
                  <b>Title:&nbsp;{muteMasks.title}</b>
                  <span>
                    Generation:&nbsp;{muteMasks.genType}
                  </span>
                  {
                    muteMasks.genType == 'One' ? (
                      <span>
                        Accessories :&nbsp;{muteMasks.accessories}
                      </span>
                    ) : (<span/>)
                  }
                  {
                    muteMasks.genType == 'One' ? (
                      <span>
                        ID :&nbsp;{muteMasks.id}
                      </span>
                    ) : (<span/>)
                  }
                  {
                    muteMasks.genType == 'One' ? (
                      <span>
                        Ranking (out of 6000) :&nbsp;{muteMasks.ranking}
                      </span>
                    ) : (<span/>)
                  }
                </div>
              </Col>
            </>
          ))}
        </Row>
      </div>
      <Footer />
    </>
  );
};
export default Wallet;


/*
<span>
  Minted Price:&nbsp;{muteMasks.acquisitionPrice}
</span>
*/
